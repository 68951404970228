import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Button } from 'vtex.styleguide'

import { GoogleSignInButton } from './shared'

const ExternalProviders = ({
  googleOAuth,
  samlProviders,
  onSamlLogin,
  onOauthLogin,
}) => {
  const { idpName: provider } = (samlProviders && samlProviders[0]) || {}

  const handleEnter = callback => e => {
    e.key === 'Enter' && callback()
  }

  const handleGoogleLogin = () =>
    onOauthLogin({
      details: {
        provider: 'google',
      },
    })

  return (
    <React.Fragment>
      {googleOAuth && (
        <div
          data-testid="google-oauth"
          className="mb5 tc"
          tabIndex="0"
          onKeyDown={handleEnter(handleGoogleLogin)}>
          <GoogleSignInButton onClick={handleGoogleLogin} />
        </div>
      )}
      {samlProviders.length > 0 && (
        <div className="mb5" data-testid="saml-provider">
          <Button
            variation="secondary"
            block
            onClick={() =>
              onSamlLogin({
                details: {
                  provider,
                },
              })
            }>
            <FormattedMessage id="admin/externalProviders.signInWith" />{' '}
            {provider}
          </Button>
        </div>
      )}
    </React.Fragment>
  )
}
ExternalProviders.propTypes = {
  googleOAuth: PropTypes.bool,
  samlProviders: PropTypes.array,
  setAlertError: PropTypes.func.isRequired,
  onOauthLogin: PropTypes.func.isRequired,
  onSamlLogin: PropTypes.func.isRequired,
}

export default ExternalProviders
