import React from 'react'
import PropTypes from 'prop-types'
import { AuthState } from 'vtex.react-vtexid'
import { FormattedMessage } from 'react-intl'
import { Button, IconMinus, ModalDialog, ToastConsumer } from 'vtex.styleguide'
import { BooleanValue } from 'react-values'

import HorizontalSeparator from './shared/HorizontalSeparator'

const SavedUsersAdmin = ({ onEditingDone }) => (
  <ToastConsumer>
    {({ showToast }) => (
      <AuthState.UserAccounts>
        {({ value: userAccounts, setValue: setUserAccounts }) => {
          const onRemove = selectedEmail => {
            const newUserAccounts = userAccounts.filter(
              item => item !== selectedEmail
            )
            setUserAccounts(newUserAccounts)
            onEditingDone({
              details: { cachedUsersLength: newUserAccounts.length },
            })
          }
          return (
            <React.Fragment>
              <div className="t-heading-4 mb7 c-on-base">
                <FormattedMessage id="admin/savedUsersAdmin.removeAnAccount" />
              </div>
              <div className="mt5">
                <div className="mb4">
                  <HorizontalSeparator />
                  {userAccounts.map((email, i) => (
                    <div key={i}>
                      <BooleanValue default={false}>
                        {({ value: showModal, toggle: toggleModal }) => (
                          <React.Fragment>
                            <div
                              className="c-action-primary hover-c-action-primary h3 flex flex-column justify-center link pointer"
                              onClick={toggleModal}>
                              <div className="flex justify-between">
                                <div className="flex justify-between c-on-base">
                                  {email}{' '}
                                </div>
                                <div className="h-100 flex flex-column justify-center">
                                  <IconMinus size={14} />
                                </div>{' '}
                              </div>
                            </div>
                            <ModalDialog
                              centered
                              confirmation={{
                                onClick: () => {
                                  toggleModal()
                                  showToast({
                                    message: (
                                      <FormattedMessage id="admin/savedUserAdmin.accountRemoved" />
                                    ),
                                  })
                                  onRemove(email)
                                },
                                label: (
                                  <FormattedMessage id="admin/button.remove" />
                                ),
                              }}
                              cancelation={{
                                onClick: toggleModal,
                                label: (
                                  <FormattedMessage id="admin/button.cancel" />
                                ),
                              }}
                              isOpen={showModal}
                              onClose={toggleModal}>
                              <div className="mw6">
                                <div className="t-heading-4 c-on-base mb4">
                                  <FormattedMessage id="admin/savedUsersAdmin.modal.title" />
                                </div>
                                <div className="t-small t-body-ns c-muted-1 lh-copy pr8-ns">
                                  <a className="c-on-base">{email}</a>{' '}
                                  <FormattedMessage id="admin/savedUsersAdmin.modal.body" />
                                </div>
                              </div>
                            </ModalDialog>
                          </React.Fragment>
                        )}
                      </BooleanValue>
                      <HorizontalSeparator />
                    </div>
                  ))}
                </div>
                <div className="ml-auto w4" data-testid="cancel">
                  <Button variation="tertiary" onClick={onEditingDone}>
                    <FormattedMessage id="admin/button.cancel" />
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </AuthState.UserAccounts>
    )}
  </ToastConsumer>
)

SavedUsersAdmin.propTypes = {
  onEditingDone: PropTypes.func.isRequired,
}

export default SavedUsersAdmin
