import loginMachine from './loginMachine'

const toCamelCase = str =>
  str
    .toLowerCase()
    .replace(/_([a-z])/g, function(m) {
      return m.toUpperCase()
    })
    .replace(/_/g, '')

export default loginMachine.events.reduce((acc, current) => {
  return {
    ...acc,
    ...(current ? { [toCamelCase(`ON_${current}`)]: current } : {}),
  }
}, {})
