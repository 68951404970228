const joinErrorMessages = (list, separator, lastSeparator) => {
  if (list.length > 1) {
    return (
      list.slice(0, -1).join(`${separator} `) +
      ` ${lastSeparator} ` +
      list.slice(-1)
    )
  }
  return list[0]
}

export default joinErrorMessages
