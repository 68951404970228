import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'vtex.styleguide'

const LoadingWithMessage = ({ topMessage, bottomMessage }) => (
  <div className="dib w-100 mv10 tc">
    <div className="dib w-100 mv4 t-heading-4 c-on-base">{topMessage}</div>
    <span className="dib w-100 c-emphasis">
      <Spinner color="currentColor" size={24} />
    </span>
    <div className="dib mv4 normal c-on-base">{bottomMessage}</div>
  </div>
)

LoadingWithMessage.propTypes = {
  bottomMessage: PropTypes.node,
  topMessage: PropTypes.node,
}

export default LoadingWithMessage
