import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IconHelp } from 'vtex.styleguide'
import { FormattedMessage } from 'react-intl'

export default class HelpLink extends Component {
  static defaultProps = {
    href: 'https://help.vtex.com/',
  }

  static propTypes = {
    href: PropTypes.string,
  }

  render() {
    return (
      <a
        className="dib link mid-gray"
        href={this.props.href}
        rel="noopener noreferrer"
        target="_blank">
        <FormattedMessage id="admin/helpLink" />{' '}
        <span className="ml2 c-muted-1">
          <IconHelp size={14} color="currentColor" />
        </span>
      </a>
    )
  }
}
