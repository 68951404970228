import React from 'react'
import PropTypes from 'prop-types'
import { Tag, IconCaretRight } from 'vtex.styleguide'
import { FormattedMessage } from 'react-intl'

import HorizontalSeparator from '../shared/HorizontalSeparator'

const MfaOptions = ({ onChooseSms, onChooseApp }) => (
  <React.Fragment>
    <div>
      <div className="t-heading-4 c-on-base mb5">
        <FormattedMessage id="admin/mfaSetup.chooseMethod.title" />
      </div>
      <div className="t-body-ns t-small c-muted-1 lh-copy">
        <FormattedMessage id="admin/mfaSetup.chooseMethod.text" />
      </div>
    </div>
    <div className="mt7 mb5">
      <HorizontalSeparator />
      <div
        className="c-action-primary h3 flex flex-column justify-center link pointer nh8 ph8 hover-bg-near-white"
        data-testid="choose-app"
        onClick={onChooseApp}>
        <div className="flex justify-between">
          <FormattedMessage id="admin/mfaSetup.chooseMethod.googleAppOption" />{' '}
          <div className="flex justify-between">
            <Tag type="success" variation="low">
              <FormattedMessage id="admin/mfaSetup.chooseMethod.recommended" />
            </Tag>
            <div className="h-100 flex flex-column justify-center ml4">
              <IconCaretRight size={14} />
            </div>
          </div>
        </div>
      </div>
      <HorizontalSeparator />
      <div
        className="c-action-primary h3 flex flex-column justify-center link pointer nh8 ph8 hover-bg-near-white"
        data-testid="choose-sms"
        onClick={onChooseSms}>
        <div className="flex justify-between">
          <FormattedMessage id="admin/mfaSetup.chooseMethod.textMessageOption" />
          <div className="h-100 flex flex-column justify-center">
            <IconCaretRight size={14} />
          </div>
        </div>
      </div>
      <HorizontalSeparator />
    </div>
  </React.Fragment>
)

MfaOptions.propTypes = {
  onChooseSms: PropTypes.func.isRequired,
  onChooseApp: PropTypes.func.isRequired,
}

export default MfaOptions
