export const RC_METRIC_NAMESPACE = 'admin-login'
export const PASSWORD = 'PASSWORD'
export const TOKEN = 'TOKEN'
export const NO_PREFERENCE = 'NO_PREFERENCE'
export const RECAPTCHA_SITEKEY = '6LejYzMUAAAAADEaWZnPjOBiyqxE7cvnKpC8kz5F'
export const TOAST_DURATION = 4000
export const SELF_APP_NAME_AND_VERSION =
  process.env.VTEX_APP_ID || process.env.VTEX_APP_NAME || null
export const NOOP = () => {}
export const RESEND_TIMER_SECONDS = 60

export const ERROR = {
  THROTTLED: 'ThrottledError',
}

export const PAGE = {
  HOME: 'admin.login-legacy.home',
  UNAUTHORIZED: 'admin.login-legacy.unauthorized',
}
