import React from 'react'
import { AuthState, AuthService } from 'vtex.react-vtexid'
import { FormattedMessage } from 'react-intl'

import LoadingWithMessage from './LoadingWithMessage'

const SamlRedirect = () => (
  <AuthState.IdentityProviders>
    {({ value: { samlProviders } }) => {
      const { id, idpName: name } = (samlProviders && samlProviders[0]) || {}

      return (
        <React.Fragment>
          <AuthService.SamlRedirect autorun useNewSession provider={id} />
          <div data-testid="saml-redirect">
            <LoadingWithMessage
              bottomMessage={
                <FormattedMessage
                  id="admin/providerLogin.redirectMessage"
                  values={{ provider: name }}
                />
              }
            />
          </div>
        </React.Fragment>
      )
    }}
  </AuthState.IdentityProviders>
)

export default SamlRedirect
