import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, InputPassword } from 'vtex.styleguide'

const TAB_KEY_CODE = 9
const ENTER_KEY_CODE = 13

export default class TabbableInput extends Component {
  static propTypes = {
    onTab: PropTypes.func,
    onEnter: PropTypes.func,
    type: PropTypes.string,
  }

  handleKey = e => {
    if ((e.charCode || e.keyCode) === TAB_KEY_CODE && this.props.onTab) {
      e.preventDefault()
      return this.props.onTab()
    }
    if ((e.charCode || e.keyCode) === ENTER_KEY_CODE && this.props.onEnter) {
      e.preventDefault()
      return this.props.onEnter()
    }
  }

  render() {
    if (this.props.type === 'password') {
      return (
        <InputPassword
          {...this.props}
          onKeyDown={this.handleKey}
          onKeyPress={this.handleKey}
        />
      )
    }
    return (
      <Input
        {...this.props}
        onKeyDown={this.handleKey}
        onKeyPress={this.handleKey}
      />
    )
  }
}
