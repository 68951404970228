import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ProviderButton extends Component {
  static defaultProps = {
    size: 'regular',
    block: false,
    variation: 'primary',
    disabled: false,
    autoFocus: false,
    type: 'button',
  }

  static propTypes = {
    /** Button size  */
    size: PropTypes.oneOf(['small', 'regular', 'large']),
    /** Block style */
    block: PropTypes.bool,
    /** onClick event */
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    icon: PropTypes.func,
    children: PropTypes.any,
  }

  handleClick = event => {
    !this.props.disabled && this.props.onClick && this.props.onClick(event)
  }

  render() {
    const { size, block, disabled, children, icon: Icon } = this.props

    let classes =
      'ba br2 bg-muted-5 hover-bg-muted-4 flex items-stretch justify-center c-on-base t-action--small b--muted-4 min-h-regular h-regular '
    let iconSize = 15

    switch (size) {
      case 'small':
        classes += 'pv3 ph4 f6 '
        break
      default:
      case 'regular':
        classes += 'pv3 ph5 f5 '
        iconSize = 26
        break
      case 'large':
        classes += 'pv3 ph6 f4 '
        iconSize = 26
        break
    }

    if (!disabled) {
      classes += 'pointer '
    }

    if (block) {
      classes += 'w-100 '
    }

    return (
      <div className={classes} onClick={this.handleClick}>
        <div className="w2 ph2 flex flex-column justify-center">
          <Icon size={iconSize} />
        </div>
        <span className="center pr7 flex flex-column justify-center">
          {children}
        </span>
      </div>
    )
  }
}

export default ProviderButton
