import React from 'react'
import { FormattedMessage } from 'react-intl'

const intlErrorByCode = {
  NetworkError: <FormattedMessage id="admin/error.NetworkError" />,
}

const getIntlError = code => {
  const error = intlErrorByCode[code]
  return error || <FormattedMessage id="admin/error.UnexpectedError" />
}

export default getIntlError
