import { RC_METRIC_NAMESPACE, SELF_APP_NAME_AND_VERSION } from './constants'

const getAccountName = () =>
  window && window.__RUNTIME__ ? window.__RUNTIME__.account : null

const selfAppVersion = `${SELF_APP_NAME_AND_VERSION}`.match(
  /[a-z0-9-.]*@(.*)$/
)[1]

export const sendMetric = metric =>
  window &&
  window.vtex &&
  window.vtex.NavigationCapture &&
  window.vtex.NavigationCapture.sendMetric &&
  window.vtex.NavigationCapture.sendMetric({
    namespace: RC_METRIC_NAMESPACE,
    ...metric,
  })

export const sendTimeToLoginMetric = ({ value, path, provider = null }) => {
  sendMetric({
    name: 'TimeToLogin',
    value,
    data: {
      accountName: getAccountName(),
      uiVersion: selfAppVersion,
      path,
      provider,
    },
  })
}

export const sendLoginStartedMetric = ({ state }) =>
  sendMetric({
    name: 'LoginStarted',
    value: 1,
    data: {
      accountName: getAccountName(),
      uiVersion: selfAppVersion,
      state,
    },
  })

export const sendStateDurationMetric = ({ value, state, nextState }) =>
  sendMetric({
    name: 'StateDuration',
    value,
    data: {
      accountName: getAccountName(),
      uiVersion: selfAppVersion,
      state,
      nextState,
    },
  })

export const sendForbiddenNavigationMetric = ({ path, event }) =>
  sendMetric({
    name: 'ForbiddenNavigation',
    value: 1,
    data: {
      accountName: getAccountName(),
      uiVersion: selfAppVersion,
      event,
      path,
    },
  })
