import React from 'react'
import PropTypes from 'prop-types'

import TokenScreen from './TokenScreen'

const TokenConfirmation = ({
  onTokenConfirmed,
  onChangeEmail,
  setAlertError,
}) => (
  <TokenScreen
    onTokenConfirmed={onTokenConfirmed}
    onChangeEmail={onChangeEmail}
    setAlertError={setAlertError}
    canSetPassword={false}
  />
)

TokenConfirmation.propTypes = {
  onTokenConfirmed: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default TokenConfirmation
