import React from 'react'
import { AuthState, AuthService } from 'vtex.react-vtexid'
import { Button, Input } from 'vtex.styleguide'
import { Value } from 'react-values'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import getIntlError from '../utils/getIntlError'

const AuthAppVerification = ({ onAppVerificationDone, setAlertError }) => (
  <React.Fragment>
    <div>
      <div className="t-heading-4 c-on-base mb5 lh-copy">
        <FormattedMessage id="admin/authAppCodeValidation.insertCode" />
      </div>
      <div className="t-body-ns t-small c-muted-1 lh-copy">
        <FormattedMessage id="admin/authAppCodeValidation.text" />
      </div>
    </div>
    <Value defaultValue="">
      {({
        value: errorMessage,
        set: setErrorMessage,
        reset: removeErrorMessage,
      }) => (
        <React.Fragment>
          <div className="mt7" data-testid="mfa-input">
            <AuthState.Token>
              {({ value: token, setValue: setToken }) => (
                <Input
                  token
                  size="large"
                  name="token"
                  label={
                    <FormattedMessage id="admin/tokenConfirmation.inputLabel.code" />
                  }
                  errorMessage={errorMessage}
                  value={token || ''}
                  onChange={e => {
                    removeErrorMessage()
                    setToken(e.target.value)
                  }}
                />
              )}
            </AuthState.Token>
          </div>
          <AuthService.ValidateMfa
            onSuccess={onAppVerificationDone}
            onFailure={error => {
              if (error.code == 'WrongCredentials') {
                setErrorMessage(
                  <FormattedMessage id="admin/tokenConfirmation.incorrectTokenError" />
                )
              } else {
                setAlertError(getIntlError(error.code))
              }
            }}>
            {({ state: { token }, loading, action: validateMfaToken }) => (
              <div className="mt5 mb5">
                <Button
                  block
                  variation="primary"
                  loading={loading}
                  onClick={() => {
                    if (!token) {
                      setErrorMessage(
                        <FormattedMessage id="admin/tokenConfirmation.codeIsRequired" />
                      )
                    } else if (token.length !== 6) {
                      setErrorMessage(
                        <FormattedMessage id="admin/tokenConfirmation.codeMustHave6Numbers" />
                      )
                    } else {
                      removeErrorMessage()
                      validateMfaToken()
                    }
                  }}>
                  <div data-testid="enter-app-code-button">
                    <FormattedMessage id="admin/button.logIn" />
                  </div>
                </Button>
              </div>
            )}
          </AuthService.ValidateMfa>
        </React.Fragment>
      )}
    </Value>
  </React.Fragment>
)

AuthAppVerification.propTypes = {
  onAppVerificationDone: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
}

export default AuthAppVerification
