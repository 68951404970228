import axios from 'axios'

export const rootPath = (__RUNTIME__ && __RUNTIME__.rootPath) || ''

const getUserAdminPermission = async ({ account, userId, rootPath }) => {
  try {
    const { data } = await axios.get(
      `${rootPath ??
        ''}/api/license-manager/pvt/accounts/${account}/logins/${userId}/granted`
    )
    return data
  } catch {
    return false
  }
}

export default getUserAdminPermission
