import React, { useCallback, useEffect, useContext } from 'react'
import { AuthState, useRedirectAfterLogin } from 'vtex.react-vtexid'
import { useRuntime } from 'vtex.render-runtime'
import { FormattedMessage } from 'react-intl'

import { PAGE } from '../commons/constants'
import LoadingWithMessage from './LoadingWithMessage'
import getUserAdminPermission from './utils/getUserAdminPermission'
import { PageWrapperContext } from '../PageWrapper'

const Redirect = ({ email, userId, redirectToReturnUrl }) => {
  const { account, navigate, rootPath } = useRuntime()

  const checkPermissionAndRedirect = useCallback(async () => {
    const hasPermission = await getUserAdminPermission({
      userId: userId || email,
      account,
      rootPath
    })

    if (hasPermission) {
      redirectToReturnUrl()

      return
    }

    navigate({ page: PAGE.UNAUTHORIZED })
  }, [account, email, navigate, redirectToReturnUrl, userId])

  useEffect(() => {
    checkPermissionAndRedirect()
  }, [checkPermissionAndRedirect])

  return (
    <LoadingWithMessage
      bottomMessage={<FormattedMessage id="admin/authenticatedUser.welcome" />}
    />
  )
}

const RedirectWrapped = ({ userId }) => {
  const { returnUrlQuery } = useContext(PageWrapperContext)
  const [redirectToReturnUrl] = useRedirectAfterLogin({
    actionArgs: { returnUrl: returnUrlQuery },
  })

  return (
    <AuthState.Email>
      {({ value: email }) => (
        <Redirect
          userId={userId}
          email={email}
          redirectToReturnUrl={redirectToReturnUrl}
        />
      )}
    </AuthState.Email>
  )
}

export default RedirectWrapped
