import { useMemo, useState, useRef, useEffect } from 'react'

const useTimerCounter = getSecsLeft => {
  const initialSecsLeft = useMemo(() => Math.ceil(getSecsLeft()), [getSecsLeft])
  const [secsLeft, setSecsLeft] = useState(initialSecsLeft)
  const intervalIdRef = useRef(null)

  useEffect(() => {
    const beginInterval = () => {
      setSecsLeft(Math.round(getSecsLeft()))
      intervalIdRef.current = setInterval(() => {
        const secsLeft = Math.round(getSecsLeft())
        setSecsLeft(secsLeft)
      }, 1000)
    }

    const millisecsUntilNextRoundedSec = (getSecsLeft() * 1000) % 1000
    const timeoutId = setTimeout(beginInterval, millisecsUntilNextRoundedSec)

    return () => {
      clearTimeout(timeoutId)
      clearInterval(intervalIdRef.current)
    }
  }, [getSecsLeft])

  return secsLeft
}

export default useTimerCounter
