import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { calcIconSize } from '../utils/icons'

const iconBase = {
  width: 18,
  height: 22,
}

export default class AppleIcon extends PureComponent {
  static defaultProps = {
    color: 'currentColor',
    size: 22,
  }

  static propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
  }

  render() {
    const { color, size } = this.props
    const newSize = calcIconSize(iconBase, size)

    return (
      <span className="c-on-muted-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width={newSize.width}
          height={newSize.height}
          viewBox="0 0 18 22"
          style={{ fill: color }}
          shapeRendering="geometricPrecision">
          <path
            d="M14.9608 11.6879C14.9917 15.0172 17.8815 16.1251 17.9135 16.1393C17.889 16.2174 17.4517 17.7181 16.391 19.2683C15.4741 20.6085 14.5224 21.9438 13.0232 21.9714C11.5501 21.9985 11.0765 21.0979 9.39231 21.0979C7.70866 21.0979 7.18239 21.9437 5.78795 21.9985C4.34087 22.0533 3.23894 20.5493 2.31437 19.214C0.425116 16.4827 -1.01866 11.4959 0.919966 8.12967C1.88304 6.45801 3.60411 5.39945 5.47219 5.3723C6.89318 5.3452 8.23443 6.3283 9.1031 6.3283C9.97122 6.3283 11.6011 5.14603 13.3146 5.31966C14.0319 5.34952 16.0454 5.60942 17.3383 7.50192C17.2341 7.56651 14.9358 8.9045 14.9608 11.6879V11.6879ZM12.1923 3.51263C12.9606 2.58268 13.4776 1.2881 13.3366 0C12.2292 0.0445073 10.8901 0.737937 10.0958 1.66738C9.38394 2.49044 8.76051 3.8078 8.92872 5.07041C10.163 5.16591 11.424 4.44317 12.1923 3.51263"
            fill="black"
          />
        </svg>
      </span>
    )
  }
}
