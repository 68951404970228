import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import CopyOnlyInput from '../CopyOnlyInput'

const TextAuthSetup = ({ code }) => (
  <React.Fragment>
    <div className="t-heading-4 c-on-base mb5 lh-copy">
      <FormattedMessage id="admin/setUpGoogleAuthApp.text.title" />
    </div>
    <div className="t-body-ns t-small c-muted-1 lh-copy">
      <FormattedMessage id="admin/setUpGoogleAuthApp.text.explanation" />
    </div>
    <div className="mt5">{code && <CopyOnlyInput value={code} />}</div>
  </React.Fragment>
)

TextAuthSetup.propTypes = {
  code: PropTypes.string,
}

export default TextAuthSetup
