import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'vtex.styleguide'
import { FormattedMessage } from 'react-intl'
import { BooleanValue } from 'react-values'
import { AuthService } from 'vtex.react-vtexid'

import TextAuthSetup from './TextAuthSetup'
import QrCodeAuthSetup from './QrCodeAuthSetup'

const SetUpAuthApp = ({ onAppSetUp }) => {
  return (
    <AuthService.RegisterMfaAuthenticator>
      {({
        action: startAuthenticatorRegistration,
        loading: isLoading,
        value: authenticatorData = {},
        error,
      }) => (
        <React.Fragment>
          <BooleanValue defaultValue>
            {({ value: isQrCodeVisible, toggle: toggleQrCode }) => (
              <React.Fragment>
                {isQrCodeVisible ? (
                  <QrCodeAuthSetup
                    loading={isLoading}
                    qrCodeUrl={authenticatorData && authenticatorData.qRCodeUrl}
                    onRetry={startAuthenticatorRegistration}
                    error={error}
                  />
                ) : (
                  <TextAuthSetup
                    code={
                      authenticatorData && authenticatorData.manualEntryCode
                    }
                    loading={isLoading}
                    error={error}
                    onRetry={startAuthenticatorRegistration}
                  />
                )}
                {error && !isLoading && (
                  <div className="tc mt8 mb9">
                    <div className="mt5 mb3 t-body-ns t-small c-danger">
                      <FormattedMessage id="admin/setUpGoogleAuthApp.errorLoading" />
                    </div>
                    <Button
                      variation="tertiary"
                      size="small"
                      onClick={startAuthenticatorRegistration}>
                      <FormattedMessage id="admin/setUpGoogleAuthApp.tryAgain" />
                    </Button>
                  </div>
                )}
                {!error && !isLoading && (
                  <div className="mt7 flex">
                    <div className="center">
                      <a
                        className="fr link c-link f6 pointer t-small"
                        onClick={toggleQrCode}>
                        {isQrCodeVisible ? (
                          <FormattedMessage id="admin/setUpGoogleAuthApp.cantScanCode" />
                        ) : (
                          <FormattedMessage id="admin/setUpGoogleAuthApp.preferQrCode" />
                        )}
                      </a>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </BooleanValue>
          <div className="mt7 mb5">
            <Button
              disabled={!authenticatorData}
              block
              variation="primary"
              onClick={onAppSetUp}>
              <div data-testid="set-up-auth-app-continue">
                <FormattedMessage id="admin/button.continue" />
              </div>
            </Button>
          </div>
        </React.Fragment>
      )}
    </AuthService.RegisterMfaAuthenticator>
  )
}

SetUpAuthApp.propTypes = {
  onAppSetUp: PropTypes.func.isRequired,
}

export default SetUpAuthApp
