import React, { useContext } from 'react'
import { AuthState } from 'vtex.react-vtexid'
import { FormattedMessage } from 'react-intl'

import SessionExpired from './components/SessionExpired'
import UnexpectedError from './components/UnexpectedError'
import LoadingWithMessage from './components/LoadingWithMessage'
import { SELF_APP_NAME_AND_VERSION } from './commons/constants'
import { PageWrapperContext } from './PageWrapper'
import StateMachine from './components/stateMachine'
import transitionsMapping from './components/stateMachine/transitionsMapping'
import componentByState from './components/utils/componentByState'

const Home = ({ identityProviders, isUserAuthenticated, userId }) => {
  const { handleErrorMessage, handleClearErrorMessage } = useContext(
    PageWrapperContext
  )

  return (
    <AuthState.UserAccounts>
      {({ value: userAccounts }) => (
        <StateMachine
          transitionsMapping={transitionsMapping}
          identityProviders={identityProviders}
          isUserAuthenticated={isUserAuthenticated}
          isUserIdentified={userAccounts.length !== 0}
          onError={handleErrorMessage}
          onClearError={handleClearErrorMessage}
          onStateChange={handleClearErrorMessage}>
          {({ state, transitionHandlers }) => {
            const StateComponent = componentByState[state]
            const props = {
              ...transitionHandlers,
              userId,
              setAlertError: handleErrorMessage,
            }
            return <StateComponent {...props} />
          }}
        </StateMachine>
      )}
    </AuthState.UserAccounts>
  )
}

const HomeWrapped = () => {
  const { emailQuery, returnUrlQuery } = useContext(PageWrapperContext)

  return (
    <AuthState
      returnUrl={returnUrlQuery}
      email={emailQuery}
      scope="ADMIN"
      parentAppId={SELF_APP_NAME_AND_VERSION}>
      {({
        loading,
        error,
        isSessionExpired,
        identityProviders,
        isUserAuthenticated,
        userId,
      }) => {
        if (isSessionExpired) {
          return <SessionExpired />
        }
        if (error) {
          return <UnexpectedError />
        }
        if (loading) {
          return (
            <LoadingWithMessage
              topMessage={
                <FormattedMessage id="admin/loadingSpinner.welcome" />
              }
            />
          )
        }
        return (
          <div data-testid="state-component">
            <Home
              identityProviders={identityProviders}
              isUserAuthenticated={isUserAuthenticated}
              userId={userId}
            />
          </div>
        )
      }}
    </AuthState>
  )
}

export default HomeWrapped
