import React from 'react'
import { FormattedMessage } from 'react-intl'

import GooglePlayIcon from '../icons/GooglePlayIcon'
import ProviderButton from './ProviderButton'

const GooglePlayDownloadButton = props => (
  <ProviderButton icon={GooglePlayIcon} {...props}>
    <FormattedMessage id="admin/googlePlayDownloadButton" />
  </ProviderButton>
)

export default GooglePlayDownloadButton
