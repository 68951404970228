import React, { useState, useCallback, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { AuthState, AuthService, validations } from 'vtex.react-vtexid'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { ToastContext, InputPassword, Input, Button } from 'vtex.styleguide'

import useTimer from '../utils/useTimer'
import { RESEND_TIMER_SECONDS, ERROR } from '../../commons/constants'
import { BorderlessButton, TimerMessage, PasswordRequirement } from '../shared'
import joinErrorMessages from '../utils/joinErrorMessages'
import useGetPasswordErrors from '../utils/useGetPasswordErrors'
import getIntlError from '../utils/getIntlError'

const messages = defineMessages({
  separator: {
    id: 'admin/passwordLogin.separator',
    defaultMessage: '',
  },
  lastSeparator: {
    id: 'admin/passwordLogin.lastSeparator',
    defaultMessage: '',
  },
})

const { validatePassword } = validations

const SetForgotPassword = ({ onChangeEmail, onPasswordSet, setAlertError }) => {
  const { showToast } = useContext(ToastContext)
  const getPasswordErrors = useGetPasswordErrors()
  const { formatMessage } = useIntl()

  const [errorMessage, setErrorMessage] = useState('')
  const [showTimer, setShowTimer] = useState(false)
  const hideTimer = useCallback(() => setShowTimer(false), [])
  const [startTimer, getSecsLeft] = useTimer({ onFinish: hideTimer })

  const handleResend = useCallback(
    resend => {
      if (getSecsLeft()) {
        setShowTimer(true)
      } else {
        resend()
      }
    },
    [getSecsLeft]
  )

  useEffect(() => startTimer(RESEND_TIMER_SECONDS), [startTimer])

  return (
    <div data-testid="set-forgot-password">
      {/* Initial texts */}
      <AuthState.Email>
        {({ value: email }) => (
          <div>
            <div className="t-heading-4 c-on-base mb5">
              <FormattedMessage id="admin/tokenConfirmation.codeSentByEmail" />
            </div>
            <div className="t-body-ns t-small c-muted-1 lh-copy">
              <FormattedMessage id="admin/tokenConfirmation.insertNumbers" />{' '}
              <div className="serious-black dib">{email}</div>
            </div>
          </div>
        )}
      </AuthState.Email>
      <div className="flex pb3 mt5 t-small">
        {/* AccessKey Resend */}
        <AuthService.SendAccessKey
          onSuccess={() => {
            showToast({
              message: (
                <FormattedMessage id="admin/tokenConfirmation.tokenResent" />
              ),
            })
            setAlertError('')
            startTimer(RESEND_TIMER_SECONDS)
          }}
          onFailure={({ code }) => {
            if (code === ERROR.THROTTLED) {
              setAlertError(
                <FormattedMessage id="admin/error.ThrottledError" />
              )
              return
            }
            setAlertError(
              <FormattedMessage id="admin/tokenConfirmation.resendTokenError" />
            )
          }}>
          {({ loading, action: sendToken }) => (
            <span className="dark-gray">
              <BorderlessButton
                onClick={() => handleResend(sendToken)}
                loading={loading}
                disabled={showTimer}>
                <FormattedMessage id="admin/tokenConfirmation.resendCode" />
              </BorderlessButton>
            </span>
          )}
        </AuthService.SendAccessKey>
        {/* Change email */}
        <div className="ml6">
          <BorderlessButton onClick={onChangeEmail}>
            <span data-testid="change-email" className="t-small">
              <FormattedMessage id="admin/tokenConfirmation.changeEmail" />
            </span>
          </BorderlessButton>
        </div>
      </div>
      {showTimer && <TimerMessage getSecsLeft={getSecsLeft} />}
      <div className="mt4">
        {/* Token */}
        <div data-testid="token-input">
          <AuthState.Token>
            {({ value: token, setValue: setToken }) => (
              <Input
                token
                type="text"
                pattern="\d*"
                size="large"
                label={
                  <FormattedMessage id="admin/tokenConfirmation.inputLabel.code" />
                }
                value={token || ''}
                onChange={e => {
                  setToken(e.target.value)
                  setErrorMessage('')
                }}
              />
            )}
          </AuthState.Token>
        </div>

        <AuthState.Password>
          {({ value: password, setValue }) => {
            const {
              passwordIsValid,
              hasMinLength,
              hasNumber,
              hasUpperCaseLetter,
              hasLowerCaseLetter,
              hasLetter,
            } = validatePassword(password || '')

            return (
              <>
                {/* Password Input */}
                <div className="mt5" data-testid="password-input">
                  <InputPassword
                    name="password"
                    id="forgotpass--pass"
                    size="large"
                    label={
                      <FormattedMessage id="admin/udpatePassword.inputLabel.password" />
                    }
                    value={password || ''}
                    onChange={e => {
                      setErrorMessage('')
                      setValue(e.target.value)
                    }}
                    errorMessage={errorMessage}
                  />
                </div>
                {/* Change Password Button */}
                <div className="mv4">
                  <AuthService.SetPassword
                    onSuccess={onPasswordSet}
                    onFailure={error => {
                      if (error.code == 'WrongCredentials') {
                        setAlertError(
                          <FormattedMessage id="admin/tokenConfirmation.incorrectTokenError" />
                        )
                        return
                      }
                      setAlertError(getIntlError(error.code))
                    }}>
                    {({ loading, action: setPassword }) => (
                      <Button
                        variation="primary"
                        block
                        isLoading={loading}
                        onClick={() => {
                          if (passwordIsValid) {
                            setPassword()
                          } else {
                            setErrorMessage(
                              <FormattedMessage
                                id="admin/passwordLogin.inlinePasswordError"
                                values={{
                                  errors: joinErrorMessages(
                                    getPasswordErrors(password),
                                    formatMessage(messages.separator),
                                    formatMessage(messages.lastSeparator)
                                  ),
                                }}
                              />
                            )
                          }
                        }}>
                        <div data-testid="create-password">
                          <FormattedMessage id="admin/updatePassword.buttton.createPassword" />
                        </div>
                      </Button>
                    )}
                  </AuthService.SetPassword>
                </div>
                <PasswordRequirement
                  hasEightCharacters={hasMinLength}
                  hasNumber={hasNumber}
                  hasUppercaseLetter={hasUpperCaseLetter}
                  hasLowercaseLetter={hasLowerCaseLetter}
                  hasLetter={hasLetter}
                />
              </>
            )
          }}
        </AuthState.Password>
      </div>
    </div>
  )
}

SetForgotPassword.propTypes = {
  onPasswordSet: PropTypes.func.isRequired,
  setAlertError: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
}

export default SetForgotPassword
