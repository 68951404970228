import React from 'react'
import { FormattedMessage } from 'react-intl'

import useTimerCounter from '../utils/useTimerCounter'

const TimerMessage = ({ getSecsLeft }) => {
  const secs = useTimerCounter(getSecsLeft)

  return (
    <div className="t-small c-danger">
      <FormattedMessage
        id="admin/timer_message.msg"
        values={{
          timeWithUnit: (
            <span className="b">
              <FormattedMessage
                id="admin/timer_message.seconds_unit"
                values={{ seconds: secs }}
              />
            </span>
          ),
        }}
      />
    </div>
  )
}

export default TimerMessage
