import { Machine } from 'xstate'

import {
  twoFactorMachine,
  twoFactorGuards,
  twoFactorActions,
} from './twoFactorMachine'

const loginMachine = Machine(
  {
    id: 'loginMachine',
    initial: 'unknown',
    states: {
      unknown: {
        on: {
          '': [
            {
              target: 'redirecting',
              cond: 'isUserLoggedIn',
            },
            {
              target: 'oauth_redirecting',
              cond: 'hasOnlyOauth',
            },
            {
              target: 'saml_redirecting',
              cond: 'hasOnlySaml',
            },
            {
              target: 'identification.identified_users',
              cond: 'isUserIdentified',
            },
            { target: 'identification' },
          ],
        },
      },
      saml_redirecting: {
        id: 'saml_redirecting',
      },
      oauth_redirecting: {
        id: 'oauth_redirecting',
        on: {
          OAUTH_LOGIN_FAILURE: '#identification',
        },
      },
      user_managing: {
        id: 'user_managing',
        on: {
          EDITING_DONE: [
            {
              target: '#unidentified_user',
              cond: 'hasNoIdentifiedUsers',
            },
            { target: '#identified_users' },
          ],
        },
      },
      identification: {
        id: 'identification',
        on: {
          OAUTH_LOGIN: '#oauth_redirecting',
          SAML_LOGIN: '#saml_redirecting',
          LOG_IN_WITH_TOKEN: 'token_login',
          LOG_IN_WITH_PASSWORD: 'password_login',
          FIRST_LOGIN: 'default_login',
        },
        initial: 'unidentified_user',
        states: {
          identified_users: {
            id: 'identified_users',
            on: {
              NOT_ME: '#unidentified_user',
              MANAGE_USERS: '#user_managing',
            },
          },
          unidentified_user: {
            id: 'unidentified_user',
            on: {},
          },
        },
      },
      password_login: {
        on: {
          FORGOT_PASSWORD: 'set_forgot_password',
          BACK: 'identification',
          PASSWORD_EXPIRED: 'update_expired_password',
          LOGGED_WITH_PASSWORD: 'redirecting',
          TWO_FACTOR_REQUIRED: 'two_factor',
        },
      },
      token_login: {
        on: {
          CHANGE_EMAIL: 'identification',
          LOGGED_WITH_TOKEN: 'redirecting',
          SWITCH_TO_PASSWORD: '#set_password',
        },
      },
      default_login: {
        id: 'default_login',
        initial: 'token_confirmation',
        states: {
          token_confirmation: {
            on: {
              TOKEN_CONFIRMED: 'choose_action',
              CHANGE_EMAIL: '#identification',
            },
          },
          choose_action: {
            on: {
              CHOOSE_CONTINUE_WITH_TOKEN: '#redirecting',
              CHOOSE_CONTINUE_WITH_PASSWORD: '#set_password',
            },
          },
        },
      },
      ...twoFactorMachine,
      update_expired_password: {
        on: {
          PASSWORD_UPDATED: 'redirecting',
          TWO_FACTOR_REQUIRED: 'two_factor',
        },
      },
      set_password: {
        id: 'set_password',
        on: {
          PASSWORD_SET: {
            target: '#redirecting',
          },
        },
      },
      set_forgot_password: {
        id: 'set_forgot_password',
        on: {
          CHANGE_EMAIL: 'identification',
          PASSWORD_SET: {
            target: '#redirecting',
          },
        },
      },
      redirecting: {
        id: 'redirecting',
        type: 'final',
      },
    },
  },
  {
    actions: {
      ...twoFactorActions,
    },
    guards: {
      ...twoFactorGuards,
      isUserLoggedIn: ({ isUserAuthenticated } = {}) => isUserAuthenticated,
      isUserIdentified: ({ isUserIdentified } = {}) => isUserIdentified,
      hasOnlyOauth: ({ identityProviders = {} } = {}) => {
        const { samlProviders = [], googleOAuth, password, accessKey } =
          identityProviders || {}
        return (
          !password && !accessKey && samlProviders.length === 0 && googleOAuth
        )
      },
      hasOnlySaml: ({ identityProviders = {} } = {}) => {
        const { samlProviders = [], googleOAuth, password, accessKey } =
          identityProviders || {}
        return (
          !password && !accessKey && samlProviders.length > 0 && !googleOAuth
        )
      },
      hasNoIdentifiedUsers: (ctx, { details } = {}) =>
        details && details.cachedUsersLength === 0,
    },
  }
)

export default loginMachine
