import React, { Component } from 'react'
import PropTypes from 'prop-types'

const ToastDuration = {
  short: 5000,
  medium: 15000,
  long: 60000,
}

export default class Toast extends Component {
  static defaultProps = {
    appearAfter: 0,
    duration: 'short',
  }

  static propTypes = {
    message: PropTypes.node.isRequired,
    action: PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      label: PropTypes.node.isRequired,
    }),
    onClose: PropTypes.func,
    appearAfter: PropTypes.number,
    duration: PropTypes.oneOf(['short', 'medium', 'long']),
  }

  state = {
    show: false,
    isClosing: false,
    appearTimeout: null,
    closeTimeout: null,
  }

  toggle = () => this.setState(state => ({ show: !state.show }))

  componentDidMount() {
    const { appearAfter, onClose, duration } = this.props

    const appearTimeout = appearAfter
      ? setTimeout(() => {
          this.toggle()
        }, appearAfter)
      : null

    const autoCloseTimeout = setTimeout(() => {
      this.setState({ isClosing: true }, () => {
        setTimeout(() => {
          this.setState({ show: false }, () => {
            if (typeof onClose === 'function') onClose()
          })
        }, 2000)
      })
    }, (ToastDuration[duration] || ToastDuration.short) + (appearAfter || 0))

    this.setState({
      show: !appearAfter,
      appearTimeout,
      autoCloseTimeout,
    })
  }

  componentWillUnmount() {
    const { appearTimeout, closeTimeout } = this.state
    if (appearTimeout) {
      clearTimeout(appearTimeout)
    }
    if (closeTimeout) {
      clearTimeout(closeTimeout)
    }
  }

  render() {
    const { show, isClosing } = this.state
    const { message, action } = this.props

    if (!show) return null

    const handleActionClick = (action && action.onClick) || undefined
    return (
      <div className="bottom-0 fixed z-5 ma7-ns mb5-ns left-2-ns w-100-s w-60-ns left-0-s left-0-ns">
        <div
          style={{ animationDuration: '200ms' }}
          className={`animated ${isClosing ? 'fadeOutDown' : 'fadeInUp'}`}>
          <div className="flex justify-between f5 c-on-base ph5 pv2 pv4-ns br2-ns bg-near-black">
            <div className="flex items-center flex-grow-1">
              <div className="pv4 ph6 flex near-white">{message}</div>
            </div>
            {action ? (
              <div className="flex flex-grow-1 justify-end">
                <button
                  type="button"
                  tabIndex="0"
                  onClick={handleActionClick}
                  className="vtex-button f5 fw5 ttu v-mid relative pv4 ph6 light-blue bg-transparent b--transparent hover-b--transparent pointer">
                  {action.label}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}
